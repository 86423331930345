@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 320px;
  padding-inline: 16px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &__text {
    font-size: 34px;
    font-weight: 900;
    background: linear-gradient(
      to bottom,
      var(--rank-color),
      var(--rank-color--strong) 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: 0 0 10px var(--rank-color--alpha);
  }

  @include media-tablet {
    margin-top: -20px;
  }
}
