@import "@/styles/theme/main.scss";
@mixin spinAnimation($prizeIndex) {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(calc($prizeIndex * -22.5deg));
    }
  }
  animation: spin 5s forwards;
}

.wrapper {
  width: 340px;
  height: 340px;
  z-index: 6;
  position: relative;
  animation: enterAnimation 1s both forwards;

  &--spinning {
    @include spinAnimation(var(--prize-index));
  }

  @keyframes enterAnimation {
    0% {
      transform: rotate(-30deg) scale(0.2);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }

  @media (max-width: 768px) {
    width: 310px;
    height: 310px;
  }

  &__label {
    position: absolute;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    top: 50%;
    margin-top: -0.8rem;
    left: 50%;
    transform-origin: -3.9rem center;
    margin-left: 3.9rem;
    justify-content: end;
    width: 100%;
    max-width: 80px;
    font-size: 12px;
    white-space: nowrap;
    transform: rotate(calc(var(--position) * 22.5deg));

    @media (max-width: 768px) {
      font-size: 12px;
      transform-origin: -3.575rem center;
      margin-left: 3.575rem;
    }
  }

  &__coin {
    width: 18px;
    height: 18px;
    object-fit: contain;
    border-radius: 100%;
  }
}
