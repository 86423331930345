@import "@/styles/theme/main.scss";
@mixin growAnimation($startScale: 0, $endScale: 3) {
  @keyframes grow {
    0% {
      transform: scale(#{$startScale});
    }
    100% {
      transform: scale(#{$endScale});
    }
  }

  animation: grow 0.5s both forwards;
}

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 16px;
  z-index: 10;
  backdrop-filter: blur(8px);
  background: linear-gradient(to bottom, transparent, $card-color);

  @include growAnimation(0, 1);

  &__lottie {
    width: 100%;
    max-width: 90%;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
  }

  &__confetti {
    width: 100%;
    max-width: 90%;
    height: 100%;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%, -50%);
  }

  &__coin {
    width: 30px;
    border-radius: 50%;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    z-index: 11;

    &__column {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    &__button {
      width: 100%;
      margin: 0 auto;
      max-width: 320px;
      position: relative;
      top: 250%;
    }

    &__title {
      font-size: 36px;
      font-weight: 700;
      color: $text-primary;
      text-shadow: 1px 1px 2px $background-default;
    }

    &__won {
      font-size: 54px;
      font-weight: 700;
      color: $primary-color;
      text-shadow: 2px 2px 4px $secondary-color;
    }

    &__coin {
      &__wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__image {
        width: 80px;
        height: 60px;
        border-radius: 50%;
      }

      &__label {
        font-size: 36px;
        color: #ffce00;
        text-shadow: 2px 2px 2px $warning-color;
      }
    }
  }

  &__image {
    width: 100%;
    max-width: 280px;
    @include growAnimation(0, 3);
  }
}
