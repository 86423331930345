@import "@/styles/theme/main.scss";
.container {
  width: 340px;
  height: 340px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(22.5deg);
  z-index: 3;

  &--spin {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &--spinning {
    display: none;
  }

  @media (max-width: 768px) {
    width: 310px;
    height: 310px;
  }
}
