@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 24px;
  background-color: $card-color;
  z-index: 12;
  position: relative;
  border-radius: $border-radius $border-radius 0 0;

  &--no-padding {
    padding: 0px !important;
  }

  &--small-mobile-padding {
    @include media-tablet {
      padding-inline: 16px;

      .container__back-button {
        left: 16px;
      }

      .container__close {
        right: 16px;
      }
    }
  }

  &--small-padding {
    padding-inline: 16px;

    .container__back-button {
      left: 16px;
    }

    .container__close {
      right: 16px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $text-primary;
  }

  &__back-button {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    z-index: 9;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $card-color;
    @include lighen(40%);

    &:hover {
      background-color: $card-color;
    }
  }

  &__close {
    z-index: 9;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }
}

.title-skeleton {
  @include skeleton($secondary: true);
  width: 120px;
  height: 18px;
}
