@import "@/styles/theme/main.scss";
@mixin spinAnimation($prizeIndex) {
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(calc(#{$prizeIndex} * -22.5deg));
    }
  }
}

.container {
  width: 100%;
  height: 340px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: enterAnimation 1s forwards;

  @keyframes enterAnimation {
    0% {
      transform: rotate(-30deg) scale(0.2);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }

  &__wrapper {
    width: 100%;
    height: 340px;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      width: 12.5rem;
      height: 15rem;
      position: absolute;
      border-radius: 50%;
      opacity: 0.65;
      z-index: 0;
      filter: blur(26px);
    }

    &::before {
      top: 16rem;
      background: radial-gradient(
        circle at 50% 50%,
        rgba(217, 21, 255, 0.6),
        rgba(0, 0, 0, 0) 75%
      );
    }

    &::after {
      top: -3rem;
      right: 5%;
      background: radial-gradient(
        circle at 50% 50%,
        rgba(7, 250, 218, 0.35),
        rgba(0, 0, 0, 0) 76%
      );
    }
  }

  &__roulette {
    width: 340px;
    height: 340px;
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    z-index: 2;

    &--spinning {
      @include spinAnimation(var(--prize-index));
      animation: spin 5s forwards;
    }
  }

  &__button {
    width: 104px;
    height: 104px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }

  &__title {
    width: 100%;
    max-width: 104px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    animation: pulse 1.5s infinite ease-in-out alternate;

    @keyframes pulse {
      from {
        transform: translate(-50%, -50%) scale(0.8) rotate(-10deg);
      }
      to {
        transform: translate(-50%, -50%) scale(0.9) rotate(0deg);
      }
    }
  }

  &__indicator {
    width: 100%;
    max-width: 160px;
    position: absolute;
    object-fit: contain;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    z-index: 7;
    animation: bounce 0.7s ease-in-out alternate;
    animation-delay: 0.5s;

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(-50%) scale(1);
      }
      50% {
        transform: translateY(-50%) scale(1.2);
      }
    }

    @media (max-width: 768px) {
      max-width: 150px;
      right: -1.9rem;
    }
  }
}
