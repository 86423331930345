@import "@/styles/theme/main.scss";
.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  width: 100%;
  height: 95%;
  border-radius: 0 0 $border-radius $border-radius;
  background-color: $background-paper;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &--smaller {
    height: 60%;

    @include media-tablet {
      height: 85%;
    }
  }

  &--default {
    background-color: $background-default;
  }
}
