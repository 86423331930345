@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 450px;
  height: auto;
  max-height: 90vh;
  background-color: $background-paper;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  padding-bottom: 24px;

  @include media-tablet {
    max-width: calc(100% - 20px);
    height: auto;
    max-height: 90vh;
    padding-bottom: 16px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-inline: 24px;

    @include hide-scrollbar;

    @include media-tablet {
      padding-inline: 16px;
    }
  }
}
