@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  position: relative;
  height: 48px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: $border-radius;
  padding-left: 16px;
  padding-right: 16px;
  color: $text-primary;
  background-color: $background-default;
  border: 1px solid $background-default;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &--container-color {
    background-color: $background-paper;
  }

  &:hover,
  &:focus {
    border: 1px solid $primary-color;
  }

  & p {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: $text-primary;
  }

  & i {
    position: absolute !important;
    right: 16px;
    top: 50%;
    font-size: 14px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: ease 0.3s;
    color: $text-primary;
  }

  &--disabled {
    pointer-events: none;
    color: $text-secondary;

    &:hover,
    &:focus {
      border: 1px solid $background-default;
    }
  }
}
