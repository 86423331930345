@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__input {
      & i {
        color: $success-color !important;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
