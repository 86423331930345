@import "@/styles/theme/main.scss";
.button,
.title,
.description {
  @include skeleton($secondary: true);
}

.button {
  width: 62px;
  height: 62px;

  &--active {
    width: 100%;
  }
}

.title {
  width: 100%;
  max-width: 100px;
  height: 14px;
  border-radius: 2px;
}

.description {
  width: 100%;
  height: 12px;
}
