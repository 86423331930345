@import "@/styles/theme/main.scss";
.button {
  all: unset;
  width: 62px;
  height: 62px;
  border-radius: $border-radius;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 16px;
  transition: all 0.3s;
  gap: 8px;
  background-color: $card-color;

  &--active {
    width: 100%;
  }

  &__image {
    width: 40px;
    height: 40px;
    pointer-events: none !important;
    object-fit: contain;
  }

  &__info {
    width: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: all 0.3s;

    &--active {
      width: 100%;
    }

    &__title {
      font-size: 14px;
      line-height: 1rem;
      font-weight: 700;
      color: $text-primary;
    }

    &__description {
      font-size: 12px;
      font-weight: 500;
      color: $text-secondary;
      line-height: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-tablet {
        white-space: wrap;
        max-height: 30px;
      }
    }
  }

  &:hover {
    background-color: $background-default;
  }

  @include media-tablet {
    height: 58px;
  }
}
