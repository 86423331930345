@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__padded {
    width: 100%;
    padding-inline: 16px;
  }
}
