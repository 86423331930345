@import "@/styles/theme/main.scss";
.button {
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  width: 100%;
  max-width: 320px;
  background-color: var(--button-color);

  &:hover {
    opacity: 0.8;
    background-color: var(--button-color);
  }
}
